export default {
  prod: {
    NODE_ENV: 'prod',
    ECS_LIB_HOST_DOMAIN: 'https://uxfabric.intuitcdn.net'
  },
  e2e: {
    NODE_ENV: 'e2e',
    ECS_LIB_HOST_DOMAIN: 'https://uxfabric.intuitcdn.net'
  },
  dev: {
    NODE_ENV: 'dev',
    ECS_LIB_HOST_DOMAIN: 'https://localhost.intuit.com:9000'
  },
  test: {
    NODE_ENV: 'test',
    ECS_LIB_HOST_DOMAIN: 'https://uxfabric.intuitcdn.net'
  }
};
