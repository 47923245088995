import { log, LOG_TYPE } from './logger';
/**
 * Feature Detect
 * @param {Array.<String>} listOfFeatures feature that needs be tested in given browser.
 * @returns {Array.<String>} list of features tha not available in given browser
 */
export const featureDetect = (listOfFeatures = []) => {
  if (!Array.isArray(listOfFeatures)) return [];

  const nonAvailableFeatures = [];

  listOfFeatures.forEach((feature) => {
    if (!(feature in window)) {
      nonAvailableFeatures.push(feature);
    }
  });

  return nonAvailableFeatures;
};

/**
 * Feature Detect that are functions available globally
 * @param {Array.<String>} listOfFeatures feature that needs be tested in given browser.
 * @returns {Array.<String>} list of features tha not available in given browser
 */
export const featureDetectForFunctions = (listOfFeatures = []) => {
  if (!Array.isArray(listOfFeatures)) return [];

  const nonAvailableFeatures = [];

  listOfFeatures.forEach((feature) => {
    if (!(feature in window && typeof window[feature] === 'function')) {
      nonAvailableFeatures.push(feature);
    }
  });

  return nonAvailableFeatures;
};

/**
 * get data attribute value from the dom
 * pass in ref to element and a list of required data attributes
 * @param {object} element from the dom
 * @param {array} dataAttributelist of list required attributes
 * @param {array} textTransform list of attributes that needs to be converted to lower-case
 * @returns {object} return a obj with corresponding to data attr list passed in.
 * note: Result values are converted to lower case.
 */
export function getDataAttributesFromElement(
  element = null,
  dataAttributelist = null,
  textTransform = []
) {
  if (!(element || dataAttributelist)) return {};

  return dataAttributelist.reduce((output, dataAttr) => {
    const temp = { ...output };
    let attrValue = (element && element.dataset && element.dataset[dataAttr]) || undefined;

    if (attrValue && textTransform.indexOf(dataAttr) > -1) {
      attrValue = attrValue.toLowerCase();
    }

    temp[dataAttr] = attrValue;

    return temp;
  }, {});
}

/**
 * Declare namespaces for the library to live.
 * @param {string} namespaceString namespace for the library to live.
 * @returns {Window} window object
 */
export function generateNamespace(namespaceString) {
  const parts = namespaceString.split('.');
  let parent = window;
  let currentPart = '';
  const { length } = parts;
  for (let i = 0; i < length; i += 1) {
    currentPart = parts[i];
    parent[currentPart] = parent[currentPart] || {};
    parent = parent[currentPart];
  }
  return parent;
}

/**
 *
 * @param {string} scriptName script name
 * @param {number} paramMaxRetries max number of retries in parameter
 * @param {string} file file name
 * @returns {Promise<any>} a promise
 * Side Effects:
 * It calls the performance module.
 *
 */
export const loadScriptPromise = (scriptName, paramMaxRetries, file) => {
  const maxRetries = paramMaxRetries || 0;
  let retriesRemaining = maxRetries;

  return new Promise((resolve, reject) => {
    /**
     * a method to load script
     */
    const loadScript = () => {
      const method = 'loadScript';
      // Create an async script element
      const scriptLoadStartTime = performance.now();
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = scriptName;
      script.onload = () => {
        if (maxRetries !== retriesRemaining) {
          // log level is INFO since this is not error for prod troubleshooting
          log(
            LOG_TYPE.INFO,
            file,
            method,
            `"Script ${script.src} loaded in ${maxRetries - retriesRemaining + 1} tries,
                timeTaken=${(performance.now() - scriptLoadStartTime).toFixed(0)} ms"`,
            '',
            false,
            `script-loading-time-${script.src}`
          );
        }
        resolve(`${scriptName}-loaded`);
      };
      script.onerror = () => {
        if (retriesRemaining > 0) {
          // log level is WARN since this is not error for prod troubleshooting
          log(
            LOG_TYPE.WARN,
            file,
            method,
            `Starting script loading retry for ${script.src}`,
            '',
            false,
            `load-script-retry-for ${script.src}`
          );
          retriesRemaining -= 1;
          loadScript();
        } else {
          log(
            LOG_TYPE.ERROR,
            file,
            method,
            `"Error loading ${script.src} timeTaken=${(
              performance.now() - scriptLoadStartTime
            ).toFixed(0)} ms"`,
            '',
            false,
            `load-script-error-${script.src}`
          );
          reject(new Error(`Failed to load script - ${scriptName}`));
        }
      };
      // Insert our script next to the first script element.
      const first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
    };
    loadScript();
  });
};
